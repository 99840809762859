import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-input-label',
  standalone: true,
  imports: [CommonModule],
  template: `
    <label
      [htmlFor]="labelFor"
      [ngClass]="labelClass"
      class="block text-base leading-6 text-mango-gray700 font-normal"
    >
      <ng-content></ng-content>
    </label>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputLabelComponent {
  @Input() labelFor!: string;
  @Input() labelClass!: string;
}
